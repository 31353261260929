import React, { useEffect, useRef, useState } from "react";

const TicketShop = ({ scriptString }) => {
  const containerRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (scriptString) {
      const parser = new DOMParser();
      try {
        const scriptEl = parser
          .parseFromString(scriptString, "text/html")
          .getElementsByTagName("script")[0];

        const src = scriptEl.getAttribute("src");
        const theme = scriptEl.getAttribute("data-fixr-theme");
        const shopId = scriptEl.getAttribute("data-fixr-shop-id");

        const script = document.createElement("script");
        script.src = src;
        script.setAttribute("data-fixr-shop-id", shopId);
        script.setAttribute("data-fixr-theme", theme);
        containerRef.current.appendChild(script);
      } catch (e) {
        setError(true);
      }
    }
  }, [scriptString]);

  return (
    <div ref={containerRef}>
      {error && (
        <div style={{ border: "1px solid red", padding: "32px" }}>
          Could not parse ticket shop tag. Please check embed code.
        </div>
      )}
    </div>
  );
};

export default TicketShop;
